import './Order.css'
import record from './MaggieCarson_TheDarkWasAglow_Cover.jpg'

const Order = () => {
  return (
    <a className='Order' href='https://openocean.nyc/maggie-carson/the-dark-was-aglow/' target='_blank' rel='noreferrer'>
      <div className='recordContainer'>
        <img className='record' src={record} alt='The Dark Was Aglow' />
      </div>
      <div className='textContainer'>
        <h2 className='title'>
          OUT NOW
        </h2>
        <button className='cta'>
          ORDER
        </button>
      </div>
    </a>
  )
}

export default Order
