import './Social.css'

const Social = () => {
  return (
    <div className='Social'>
      <a href='https://www.instagram.com/maggiecarsonmusic/' target='_blank' rel='noreferrer'>
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19" cy="19" r="18" stroke="#EDE3BF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M22.1714 11.1539H15.8287C13.251 11.1539 11.1539 13.251 11.1539 15.8287V22.1714C11.1539 24.7491 13.251 26.8462 15.8287 26.8462H22.1714C24.7491 26.8462 26.8462 24.7491 26.8462 22.1714V15.8287C26.8461 13.251 24.749 11.1539 22.1714 11.1539ZM25.2675 22.1714C25.2675 23.8813 23.8813 25.2675 22.1714 25.2675H15.8287C14.1187 25.2675 12.7325 23.8813 12.7325 22.1714V15.8287C12.7325 14.1187 14.1187 12.7325 15.8287 12.7325H22.1714C23.8813 12.7325 25.2675 14.1187 25.2675 15.8287V22.1714ZM14.9414 19C14.9414 16.7621 16.7621 14.9414 19 14.9414C21.2379 14.9414 23.0586 16.7621 23.0586 19C23.0586 21.2379 21.2379 23.0586 19 23.0586C16.7621 23.0586 14.9414 21.2379 14.9414 19ZM19 21.48C17.6304 21.48 16.5201 20.3697 16.5201 19C16.5201 17.6304 17.6304 16.5201 19 16.5201C20.3697 16.5201 21.48 17.6304 21.48 19C21.48 20.3697 20.3697 21.48 19 21.48ZM23.0665 15.9444C23.6036 15.9444 24.039 15.509 24.039 14.9719C24.039 14.4348 23.6036 13.9994 23.0665 13.9994C22.5294 13.9994 22.094 14.4348 22.094 14.9719C22.094 15.509 22.5294 15.9444 23.0665 15.9444Z" fill="#EDE3BF"/>
        </svg>
      </a>
      <a href='https://www.facebook.com/maggiecarsonmusic/' target='_blank' rel='noreferrer'>
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19" cy="19" r="18" stroke="#EDE3BF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.2177 26.3846H16.9438V19.4105H15.3077V16.7229H16.9438V15.1107C16.9438 12.9198 17.8675 11.6154 20.492 11.6154H22.6767V14.303H21.3117C20.2897 14.303 20.2217 14.6781 20.2217 15.3791L20.2177 16.7229H22.6923L22.4024 19.4105H20.2177V26.3846Z" fill="#EDE3BF"/>
      </svg>
      </a>
      <a href='https://open.spotify.com/artist/1x9Hg0Pz1RT3zA0jfHswTh?si=6N_71k-8RFqdHw8qUsHDQQ' target='_blank' rel='noreferrer'>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19" cy="19" r="18" stroke="#EDE3BF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M11.1539 18.9976C11.1539 14.6725 14.6723 11.1539 18.997 11.1539C23.325 11.1539 26.8462 14.6725 26.8462 18.9976C26.8462 23.3253 23.325 26.8462 18.997 26.8462C14.6723 26.8462 11.1539 23.3253 11.1539 18.9976ZM17.7877 21.4625C16.8584 21.4625 15.9261 21.6029 15.0168 21.8797C14.8284 21.9413 14.684 22.4506 14.7538 22.6801C14.8055 22.848 14.9596 22.9611 15.1373 22.9611C15.1779 22.9611 15.2185 22.955 15.2581 22.943C16.0692 22.6901 16.9205 22.5618 17.7877 22.5618C19.2231 22.5618 20.6466 22.9273 21.9045 23.6187C21.9673 23.6526 22.0301 23.6687 22.1016 23.6687C22.2458 23.6687 22.3825 23.5876 22.4498 23.4622C22.5032 23.3644 22.5164 23.1765 22.4832 22.9937C22.4499 22.8109 22.3758 22.6641 22.2943 22.6197C20.9241 21.8625 19.366 21.4625 17.7877 21.4625ZM14.6746 18.9899C15.6881 18.7144 16.7355 18.5746 17.7878 18.5746C19.6793 18.5746 21.5577 19.0323 23.2209 19.8989C23.3375 19.9581 23.4235 20.0584 23.4628 20.1814C23.5027 20.3059 23.4907 20.4399 23.4284 20.5594C23.28 20.8499 23.1065 21.0451 22.9966 21.0451C22.9187 21.0451 22.8435 21.0264 22.7687 20.989C21.2242 20.1847 19.5484 19.7769 17.7877 19.7769C16.8084 19.7769 15.8475 19.9057 14.9328 20.1595C14.8902 20.171 14.8466 20.1768 14.8031 20.1768C14.583 20.1768 14.3891 20.0282 14.3317 19.816C14.2582 19.5399 14.4262 19.0574 14.6746 18.9899ZM14.4458 17.1191C14.493 17.1191 14.5405 17.1133 14.587 17.1018C15.6421 16.8426 16.7189 16.7112 17.7878 16.7112C19.8675 16.7112 21.8565 17.1787 23.6994 18.1007C23.7778 18.1376 23.8664 18.1578 23.9527 18.1578C24.1765 18.1578 24.3795 17.9553 24.4735 17.7673C24.5424 17.6276 24.5533 17.4696 24.5039 17.3227C24.4545 17.1761 24.3506 17.0574 24.2113 16.9887C22.2051 15.9879 20.0441 15.4806 17.7877 15.4806C16.6221 15.4806 15.4499 15.6233 14.3035 15.9049C14.0087 15.9792 13.8052 16.3571 13.8854 16.6824C13.9494 16.9394 14.1799 17.1191 14.4458 17.1191Z" fill="#EDE3BF"/>
        </svg>
      </a>
      <a href='https://www.youtube.com/channel/UCWE8qDbGwblM_K3lpLEZ8FA/videos' target='_blank' rel='noreferrer'>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19" cy="19" r="18" stroke="#EDE3BF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M26.4769 13.5077C26.9639 14.0657 27.1358 15.3287 27.1358 15.3287C27.1358 15.3287 27.3077 16.8266 27.3077 18.3244V19.7049C27.3077 21.1734 27.1358 22.6713 27.1358 22.6713C27.1358 22.6713 26.9639 23.9343 26.4769 24.4923C25.8776 25.1626 25.2005 25.2221 24.8646 25.2515C24.8472 25.2531 24.8308 25.2545 24.8154 25.2559C22.495 25.4615 19 25.4615 19 25.4615C19 25.4615 14.6743 25.4322 13.3565 25.2854C13.2865 25.2688 13.2003 25.2564 13.1022 25.2422C12.6788 25.1813 12.0348 25.0885 11.5231 24.4923C11.0361 23.9343 10.8642 22.6714 10.8642 22.6714C10.8642 22.6714 10.6923 21.2028 10.6923 19.7049V18.3245C10.6923 16.8266 10.8642 15.3287 10.8642 15.3287C10.8642 15.3287 11.0074 14.0657 11.5231 13.5077C12.1225 12.8373 12.7995 12.7779 13.1355 12.7484C13.1528 12.7469 13.1692 12.7455 13.1846 12.744C15.505 12.5678 19 12.5385 19 12.5385C19 12.5385 22.495 12.5678 24.8154 12.744C24.8393 12.7463 24.8656 12.7483 24.8939 12.7506C25.2388 12.7776 25.8945 12.8292 26.4769 13.5077ZM16.9947 22.407V16.0336L22.4377 19.2937L16.9947 22.407Z" fill="#EDE3BF"/>
        </svg>
      </a>
      <a href='mailto:magdalenecarson@gmail.com' target='_blank' rel='noreferrer'>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19" cy="19" r="18" stroke="#EDE3BF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M13.9403 13.9261H24.0423C24.6134 13.911 25.172 14.1034 25.6237 14.4708L25.048 14.8626L18.9723 18.9673L17.3909 17.888C17.3583 17.8554 17.3195 17.8305 17.277 17.815L12.9314 14.8593L12.3589 14.4708C12.8038 14.0902 13.3672 13.8962 13.9403 13.9261ZM11.9604 14.9822L12.4949 15.3475L16.6729 18.2002L12.5328 22.7167L12.0837 23.2049C11.7717 22.7473 11.6076 22.1968 11.6156 21.6341L11.6188 16.3571C11.6143 15.875 11.7322 15.4005 11.9604 14.9822ZM25.0101 23.165L20.7877 18.5688L19.1747 19.6647L19.1209 19.6913H19.0956C19.0694 19.6948 19.0428 19.6948 19.0166 19.6913C18.9903 19.6948 18.9638 19.6948 18.9375 19.6913H18.9122L18.8363 19.6647L17.2359 18.5755L12.9915 23.1783L12.536 23.6731C12.9537 23.9553 13.444 24.0955 13.9403 24.075H24.0612C24.5556 24.0783 25.0402 23.9306 25.456 23.6499L25.0101 23.165ZM26.3827 16.367V21.6341C26.4039 22.1956 26.2434 22.7481 25.9273 23.2016L25.475 22.7067L21.3381 18.1869L25.513 15.3475L26.0506 14.9855C26.2818 15.4045 26.3968 15.883 26.3827 16.367Z" fill="#EDE3BF"/>
        </svg>
      </a>
    </div>
  )
}

export default Social
