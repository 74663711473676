import './Header.css'

const Header = () => {
  return (
    <div className='Header'>
      <svg id="MaggieCarson" data-name="Maggie Carson" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.9 27.68">
        <path className="cls-1" d="M327,19.78c.08-.14.11-.17.11-.2.09-3.71.16-7.41.27-11.11a19.9,19.9,0,0,0-.57-4.81,1.33,1.33,0,0,1,1-1.74,1.56,1.56,0,0,1,1.93,1.24,42.3,42.3,0,0,1,.44,10c-.31,4.07-.1,8.13-.19,12.2,0,.75-.2,1.38-1,1.51s-1.6.06-2-.88a30.87,30.87,0,0,0-4.45-7.58c-2.44-3.06-3.95-6.74-6.57-9.73-.34.35-.17.81-.17,1.21,0,5.47,0,11,0,16.42,0,1-.26,1.73-1.4,1.78s-1.49-.53-1.49-1.8c0-4.24-.12-8.48,0-12.72.11-3,.59-6,.95-8.92a1.05,1.05,0,0,1,.7-1c.44-.12.73.13,1,.42,1.44,1.32,2.22,3.16,3.52,4.57,1.86,2,2.85,4.6,4.56,6.7C324.91,16.72,325.88,18.23,327,19.78Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M301.2,15.28a16.06,16.06,0,0,1-.68,5.83c-1.45,3.89-5.66,7.32-9.64,7a34.27,34.27,0,0,1-3.94-.1c-2.88-.6-4.54-2.64-5.37-5.35a23.71,23.71,0,0,1-1-7.45,12.72,12.72,0,0,1,2.8-7.32c1.67-2.29,4.1-3.47,6.46-4.8a2,2,0,0,1,1.5-.16c2.55.62,5.09,1.34,7,3.25a8.75,8.75,0,0,1,2.86,6.24C301.21,13.36,301.2,14.32,301.2,15.28Zm-17.74.6a14.44,14.44,0,0,0,.25,3.66,10.65,10.65,0,0,0,.61,1.89c.93,3.1,1.72,3.73,4.85,4.14a7.78,7.78,0,0,0,8.63-6.06,23.92,23.92,0,0,0,.35-7.28A7.27,7.27,0,0,0,295,7.12c-1.7-1.37-3.88-.85-5.74-1.58-.09,0-.28.1-.4.2C285.64,8.3,283.1,11.25,283.46,15.88Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M18.36,8.92a16.34,16.34,0,0,0-4.52,5.89c-.78,2-2.18,2.4-3.64.55C8.09,12.67,6,10,3.78,7.24a2.09,2.09,0,0,0-.17,1.3c0,5.6,0,11.2,0,16.8a2.38,2.38,0,0,1-.34,1.47c-.17.24-.39.64-.73.49s-.86-.21-.84-.72c0-.69-.28-1.3-.28-2q0-9.12,0-18.24A7.23,7.23,0,0,1,1.7,4.73a1.32,1.32,0,0,1,2.09-.8,3.35,3.35,0,0,1,.6.59c2.22,2.51,4.44,5,6.41,7.74.75,1,.74,1,1.46,0A22.33,22.33,0,0,1,16.84,7a7.61,7.61,0,0,0,1.95-2.08,2.82,2.82,0,0,1,1-1,1.47,1.47,0,0,1,1.46.22c.53.33.41.88.31,1.31-1,4.46-.5,9-.34,13.46.1,2.9-.19,5.78-.14,8.67a1.33,1.33,0,0,1-1.48,1.47c-1.1,0-1.14-.74-1.14-1.56,0-1.43.25-2.87.2-4.27-.18-4.63-.17-9.25-.2-13.88A2.16,2.16,0,0,0,18.36,8.92Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M231.12,23.44c-.33-5-.2-10-.26-15.06,0-1.24.26-2.43.29-3.66s1.1-1.52,1.92-1.54c2.35,0,4.66-.55,7.06.12,3.49,1,5.11,3,5.56,6.26.19,1.45-.93,2.59-1.8,3.7a7,7,0,0,1-2.91,2c-.57.24-.71.46-.4,1.12,1.2,2.58,2.08,5.3,3.06,8,.29.76.83,1.42,1.17,2.17s.62,1.43-.39,1.89a1.55,1.55,0,0,1-2.15-.76c-1.92-3.48-2.65-7.41-4.16-11-.16-.37-.26-.51-.62-.49a14.32,14.32,0,0,1-3.75-.39c-.49-.1-.48.15-.43.43.7,3.82.37,7.68.46,11.52,0,.84-.2,1.4-1.17,1.4s-1.48-.42-1.48-1.4Zm5-18.39a9.68,9.68,0,0,0-1.32,0c-.86.12-1.45.37-1.35,1.52a47.29,47.29,0,0,1,0,4.91c0,.69-.09,1.38-.13,2.07a8,8,0,0,1,2.06-.09,7.65,7.65,0,0,0,5-.72A4.16,4.16,0,0,0,243,9.61,3.61,3.61,0,0,0,240.7,6,11,11,0,0,0,236.15,5.05Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M105.6,22a22.22,22.22,0,0,0,.28,4.75,1.83,1.83,0,0,1-1.51,2.17c-3.26.76-6.57,1.17-9.64-.57-3.55-2-6-4.86-6.66-9.06A18.25,18.25,0,0,1,89.35,8.56c1-2.13,3.88-5.08,7.23-5,1.67.06,3.31-.36,5-.25a4.77,4.77,0,0,1,1.48.33c.37.15.9.51.8.93a1.1,1.1,0,0,1-1.11.9c-2-.19-3.92.49-5.9.5-2.42,0-4.46,2.15-5.15,3.91a15.54,15.54,0,0,0-.46,10.93c.8,2.41,4.17,6.09,7.58,6.11,1,0,2.09,0,3.14-.07.8,0,1.21-.45,1-1.21a13.49,13.49,0,0,1-.19-4.15c0-1.48-.25-2.92-.28-4.39a1.45,1.45,0,1,1,2.9,0C105.29,18.76,105.83,20.36,105.6,22Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M76.56,21.26v4.08c0,1.43-.48,1.95-1.82,2.52a8.63,8.63,0,0,1-5.22.15c-1.31-.25-2.67-.14-3.92-.71C62.35,25.79,60.88,23,60,19.72a24.61,24.61,0,0,1-.67-8.23A8.87,8.87,0,0,1,62.5,5.37a13.79,13.79,0,0,1,5.6-3.32A3.32,3.32,0,0,1,69.62,2c1.44.33,3,.27,4.35.9a2.38,2.38,0,0,1,.77.5c.49.47.9,1.15.54,1.68s-1.09.26-1.72.06c-1.75-.59-3.57-1.2-5.39-.52-3.21,1.22-6.56,4.49-6.29,8.5.2,3,.35,6,1.64,8.86a5.44,5.44,0,0,0,4.12,3.44c1.39.22,2.79.45,4.21.54,1.62.1,1.83-.14,1.83-1.72V16.52c0-1.22.52-1.92,1.45-1.87,1.13.06,1.4.9,1.42,1.82C76.59,18.07,76.56,19.67,76.56,21.26Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M48.73,27c0,.92-.34,1.39-1.07,1.53a1.31,1.31,0,0,1-1.55-1c-.4-1.34-.68-2.72-1-4.09a1.27,1.27,0,0,0-1.33-1.21c-2-.24-4.12.07-6.14-.41a.89.89,0,0,0-1.16.68c-.39,1.15-.83,2.28-1.19,3.43-.24.76-.52,1.51-1.46,1.24-1.11-.31-.74-1.16-.51-1.94a77.87,77.87,0,0,0,2-7.71A48.55,48.55,0,0,1,37.91,8,36.89,36.89,0,0,1,39.72,3.9c.65-1.18,1.72-1.34,2.46-.17A20.8,20.8,0,0,1,44.71,10c.75,3,1.41,6.08,2.15,9.11C47.51,21.81,47.91,24.56,48.73,27ZM37.21,20.16h6.64c.64,0,.54-.29.43-.76-.72-3-1.34-6.1-2.08-9.12a8,8,0,0,0-1.26-3.49A62.59,62.59,0,0,0,37.21,20.16Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M217.73,29c-1.49,0-2.1-.63-2-2a8.25,8.25,0,0,0-1.23-4.64c-.39-.74-1.5-.31-2.26-.56a10.13,10.13,0,0,0-3.3-.26c-.12,0-.27,0-.35,0-1.68-1.23-1.51.29-1.75,1.17-.31,1.17-.68,2.33-.89,3.51-.15.85-.65,1-1.26.81a1,1,0,0,1-.65-1.34q.55-2.58,1.12-5.14a106.09,106.09,0,0,1,2.67-11c.73-2.13,1.81-4.14,2.73-6.2a1.19,1.19,0,0,1,1.33-.72c.61,0,.83.42,1,1,.83,3.49,1.89,6.94,2.53,10.46a57,57,0,0,0,1.84,7.5c.57,1.74.83,3.66,2,5.17a1,1,0,0,1,.06,1.31C218.94,28.73,218.47,29.21,217.73,29ZM211.28,7.42c-.17.27-.25.4-.32.53-1.82,3.31-2,7.09-3.12,10.61-.3,1-.38,1.73,1,.91.21-.13.55,0,.82,0a29.78,29.78,0,0,1,3.54.24c.41,0,.76,0,.58-.58a54.58,54.58,0,0,1-1.14-5.49C212.2,11.6,211.76,9.59,211.28,7.42Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M265.14,2.16a18.24,18.24,0,0,1,4.95,1c.57.14.94.47.85,1.08a.93.93,0,0,1-1.22.82c-1.27-.28-2.57-.36-3.83-.62-2-.41-5.06,1.89-5.27,3.94-.27,2.67.94,4.32,3.23,5.57,2,1.08,4.26,1.84,5.65,3.75s2.86,4.07,1.86,6.69c-.85,2.25-2.72,3.43-4.92,4.22-2.79,1-5.3.24-7.74-1.1a3.61,3.61,0,0,0-.88-.33,1.69,1.69,0,0,1-1.46-1.75c0-1.05.85-1.55,1.82-1.7a1.84,1.84,0,0,1,2,.82,4.31,4.31,0,0,0,4.35,1.67c1.5-.31,2.88-.74,3.69-2.19a2.71,2.71,0,0,0,.16-2.49,8,8,0,0,0-3.73-4.12c-1.86-1.05-3.93-1.89-5.35-3.71a6.88,6.88,0,0,1,1.55-9.93C263,2.35,263.38,2.16,265.14,2.16Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M133.92,23.54c0-3.48.82-6.87,1.12-10.32a27.42,27.42,0,0,0-.14-7.62,1.78,1.78,0,0,1,1.84-2.26c1-.07,2,.29,3.07.27a39.91,39.91,0,0,1,4.63-.25,2.8,2.8,0,0,1,.83.06c.34.15.82.2.87.7s-.31.71-.66.9a11.41,11.41,0,0,1-3.06.74,15.08,15.08,0,0,1-3.91.23c-.55,0-.77.15-.76.74q0,3.36,0,6.72c0,.61.24.73.78.72,1.9-.05,3.78-.24,5.67-.44.74-.08,1.6-.38,2.25.36a.93.93,0,0,1,.11,1.12c-.48.68-1.3.55-2,.65a42.08,42.08,0,0,1-6.25.2c-.62,0-.94.28-1,.78-.12,2.33-.79,4.59-.75,6.93,0,1.91.05,1.74,1.91,1.95,2.8.32,5.63-.07,8.41.44.81.14,1.33.46,1.2,1.4s-.78,1-1.48,1c-3.7.19-7.36-.55-11.06-.52-1.14,0-1.55-.95-1.61-2C133.88,25.22,133.92,24.38,133.92,23.54Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M186.67,2.89c2.27-.11,4.32.65,6.45.74a.86.86,0,0,1,.8.89,1,1,0,0,1-.72,1,5.87,5.87,0,0,1-4.07,0c-3.5-1-7.08,1.76-8.4,4-2.67,4.59-1.35,8.82,1,13,1.54,2.75,4,3.6,7,3.17a9.76,9.76,0,0,0,3.89-1.18c1-.61,1.85-.38,2.26.5s-.31,1.48-1,1.92A10.68,10.68,0,0,1,190,28a10,10,0,0,1-6.57-.5,11.18,11.18,0,0,1-5.52-6.39,17.58,17.58,0,0,1-1.42-5.66,11.19,11.19,0,0,1,1.35-6.24,12.15,12.15,0,0,1,5-5.16C184.05,3.44,185.26,2.59,186.67,2.89Z" transform="translate(-1.42 -1.9)"/>
        <path className="cls-1" d="M121,9.44c-.11,3.81.13,7.75.35,11.67a30,30,0,0,1-.85,6.81,1.19,1.19,0,0,1-1.57,1c-.69-.18-1.25-.58-1-1.57,1.06-3.46.87-7,.7-10.55-.14-2.87-.73-5.75-.11-8.65.25-1.15-.16-2.37.27-3.53.21-.57.46-1.23,1.13-1.14s1,.75,1,1.5C121,6.43,121,7.87,121,9.44Z" transform="translate(-1.42 -1.9)"/>
      </svg>
    </div>
  )
}

export default Header
