import Header from './Header/Header'
import Order from './Order/Order'
import Social from './Social/Social'
import './App.css'

const  App = () => {
  return (
    <div className='App'>
      <Header />
      <Social />
      <Order />
      <footer className='footer'>
        <Social />
      </footer>
    </div>
  )
}

export default App
